import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import { getAssetUrl } from '../services/cdn'
import { Container as BlockContainer, VideoProduct } from '../components/Blocks'
import { HeroCarousel, Hero } from '../components/Sections'
import { Container, Row, Col } from 'reactstrap'

const IndexPage = () => (
  <Layout>
    <SEO title="Offerings" keywords={[`gatsby`, `application`, `react`]} />
    <BlockContainer padding={4} className="mt-3">
      <Container fluid className="p-0">
        <Row>
          <Col md="12">
            <HeroCarousel
              items={[
                {
                  src: getAssetUrl('agriculture/Rice-Mill-6.jpg'),
                  altText: 'RICEMILL',
                  captionHeader: '',
                  caption: '',
                },
                {
                  src: getAssetUrl('agriculture/Contract-Growing-3.jpg'),
                  altText: 'CONTRACT GROWING',
                  captionHeader: '',
                  caption: ''
                },
                {
                  src: getAssetUrl('agriculture/Layer-Farm-4.jpg'),
                  altText: 'LAYER FARM',
                  captionHeader: '',
                  caption: ''
                },
                {
                  src: getAssetUrl('agriculture/KoopLikas-4.jpg'),
                  altText: 'KOOPLIKAS',
                  captionHeader: '',
                  caption: ''
                },
                {
                  src: getAssetUrl('agriculture/KoopLikas-2.jpg'),
                  altText: 'KOOPLIKAS',
                  captionHeader: '',
                  caption: ''
                },
                {
                  src: getAssetUrl('agriculture/KoopLikas-5.jpg'),
                  altText: 'KOOPLIKAS',
                  captionHeader: '',
                  caption: ''
                }
              ]}
            />
          </Col>
        </Row>
      </Container>
    </BlockContainer>
    <VideoProduct
      id="feedmill"
      partnerId="partnerfeedmill"
      title="FEEDMILL INSIGHTS"
      advantagesTitle="Main Points and Advantages of Feedmill"
      advantages={[
        { iconClass:"fa fa-pencil-square-o", name: "ISO 9001:2015 Feedmills Certified"},
        { iconClass:"fa fa-pencil-square-o", name: "Provides Swine Management Seminars"},
        { iconClass:"fa fa-pencil-square-o", name: "Consistent Internationally-Accredited Animal Feeds Manufacturer Awardee"},
        { iconClass:"fa fa-pencil-square-o", name: "With over 60 SIDC Feeds outlets nationwide"},
        { iconClass:"fa fa-pencil-square-o", name: "Provides Technical Assistance to Raisers"}
      ]}
      videoLinks={[
        { link: "agriculture/video/feedmill-video.m4v", type: 'video/ogg' },
        { link: "agriculture/video/feedmill-video.mp4", type: 'video/mp4' }
      ]}
      offerTitle="SIDC Feedmill offers"
      offers={[
        { name: 'Hog and Swine Feeds Product', iconClass: 'fa fa-check-square-o' },
        { name: 'Poultry Feeds Products', iconClass: 'fa fa-check-square-o' },
        { name: 'Ruminants Feeds Products', iconClass: 'fa fa-check-square-o' },
        { name: 'Aqua Feeds Products', iconClass: 'fa fa-check-square-o' },
        { name: 'Game Fowl Feeds', iconClass: 'fa fa-check-square-o' },
        { name: 'Dog Food', iconClass: 'fa fa-check-square-o' },
        { name: 'Technical and Veterinary Services', iconClass: 'fa fa-check-square-o' },
        { name: 'SIDC Feeds Outlet Franchising', iconClass: 'fa fa-check-square-o' }
      ]}
      photoLinks={[
        'agriculture/Feedmill-Offerings-4.png',
        'agriculture/Feedmill-Offerings-2.png',
        'agriculture/Feedmill-Offerings-3.png',
        'agriculture/Feedmill-Offerings-1.png'
      ]}
      departmentTitle="SIDC Feedmill partnering Departments"
      department1={{
        // link: '/',
        src: 'agriculture/SIDC-KooPinoy-Partnering.png'
      }}
      department2={{
        // link: '/',
        src: 'agriculture/SIDC-Paiwi.png'
      }}
      department3={{
        // link: '/',
        src: 'agriculture/SIDC-Sales-Department.png'
      }}
    >
      <h3>SIDC Feedmill at a glance</h3>
      <div className="lead">
        <p>Sorosoro Ibaba Development Cooperative started manufacturing feeds for livestocks last March 1987 and the workflow and production process was certified by the International Organization for Standardization under ISO 9001:2011 last 2011 and upgraded to ISO 9001:2015 last November 2018.</p>
        <p>With the SIDC Feedmill feeds manufacturing expertise, and its vision to provide complete feeds product lines for existing members and potential customers. SIDC Feedmill Plant is located at Sorosoro Karsada, Batangas City.</p>
      </div>
    </VideoProduct>
    <VideoProduct
      id="contract-growing"
      partnerId="partnercontractgrow"
      title="SIDC Contract Growing"
      advantagesTitle="Main Points and Advantages of Contract Growing"
      advantages={[
        { iconClass:"fa fa-pencil-square-o", name: "Provides Quality Inputs for Contract Growing"},
        { iconClass:"fa fa-pencil-square-o", name: "Buy back Scheme"},
        { iconClass:"fa fa-pencil-square-o", name: "SIDC Provides Biogas Digesters to raisers"},
        { iconClass:"fa fa-pencil-square-o", name: "Complaint to the environmental requirements"},
        { iconClass:"fa fa-pencil-square-o", name: "Provides additional learnings through Swine Management Seminar"},
      ]}
      videoLinks={[
        { link: "agriculture/video/SIDC-Contract-Growing-1.m4v", type: 'video/ogg' },
        { link: "agriculture/video/SIDC-Contract-Growing-1.mp4", type: 'video/mp4' }
      ]}
      offerTitle="Contract Growing"
      offers={[
        { name: 'Quality Meat Products', iconClass: 'fa fa-check-square-o' },
        { name: 'Quality Piglets, Feeds, Medication and Technical Assistance', iconClass: 'fa fa-check-square-o' },
        { name: 'Hog and Swine Feeds Product', iconClass: 'fa fa-check-square-o' },
        { name: 'Marketing of Hogs', iconClass: 'fa fa-check-square-o' }
      ]}
      photoLinks={[
        // 'app/dummy-4-250x250.png',
        'agriculture/Contract-Growing pic-1.jpg',
        'agriculture/Contract-Growing pic-2.jpg',
        // 'app/dummy-4-250x250.png',
      ]}
      departmentTitle="Contract Growing partnering Departments"
      department1={{
        // link: '/',
        src: 'agriculture/SIDC-KooPinoy-Partnering.png'
      }}
      department2={{
        // link: '/',
        src: 'agriculture/SIDC-Paiwi.png'
      }}
      department3={{
        // link: '/',
        src: 'agriculture/SIDC-Sales-Department.png'
      }}
    >
      <h3>Contract growing at a glance</h3>
      <div className="lead">
        <p>The cooperative also offers Contract Growing of hogs to its members by providing them all necessary inputs like piglets, feeds, medications and technical assistance while also ensuring the market for their live hogs.</p>
        <p>SIDC always make sure that PAIWI availers as well its owned piggeries are all compliant with environmental requirement. SIDC provides the Biogas Digester to Paiwi availers to make sure that there is a proper waste management.</p>
      </div>
    </VideoProduct>
    <VideoProduct
      id="layer-farm"
      partnerId="partnerlayerfarm"
      title="SIDC Layer Farm"
      advantagesTitle="Main Points and Advantages of Layer Farm"
      advantages={[
        { iconClass:"fa fa-pencil-square-o", name: "Quality egg rich in Omega 3"},
        { iconClass:"fa fa-pencil-square-o", name: "Layers are being fed by SIDC Feeds"},
        { iconClass:"fa fa-pencil-square-o", name: "Members can avail wholesale buying of eggs"},
        { iconClass:"fa fa-pencil-square-o", name: "SIDC offers extended credit line for members who wants to sell eggs for business"},
        { iconClass:"fa fa-pencil-square-o", name: "Golden Lays Egg are available in all SIDC Consumer Retailing branches"}
      ]}
      videoLinks={[
        { link: "agriculture/video/SIDC-LayerFarm.m4v", type: 'video/ogg' },
        { link: "agriculture/video/SIDC-LayerFarm.mp4", type: 'video/mp4' }
      ]}
      offerTitle="Layer Farm Products"
      offers={[
        { name: 'Wholesale', iconClass: 'fa fa-check-square-o' },
        { name: "Golden Lays Egg 30's", iconClass: 'fa fa-check-square-o' },
        { name: "Golden Lays Egg 12's", iconClass: 'fa fa-check-square-o' },
        { name: "Golden Lays Egg 6's", iconClass: 'fa fa-check-square-o' }
      ]}
      photoLinks={[
        // 'app/dummy-4-250x250.png',
        'agriculture/Picture1.png',
        'agriculture/Picture2.png',
        // 'app/dummy-4-250x250.png'
      ]}
      departmentTitle="Layer Farm partnering Departments"
      department1={{
        // link: '/',
        src: 'agriculture/SIDC-KooPinoy-Partnering.png'
      }}
      department2={{
        // link: '/',
        src: 'agriculture/SIDC-Paiwi.png'
      }}
      department3={{
        // link: '/',
        src: 'agriculture/SIDC-Sales-Department.png'
      }}
    >
      <h3>SIDC Layer Farm at a glance</h3>
      <div className="lead">
        <p>Another project of the cooperative located at Balanga, Ibaan Batangas that provides regular supply of eggs to the members and other customers.</p>
        <p>SIDC Layer Farm produces 60,000 of eggs. These eggs are being sold to SIDC Partners, large institutions and to SIDC Coop Supermarts, Coopmarts, One-Stop-Shops, and 1Click Shop.</p>
      <p>Produced eggs are packed into 30’s or per tray, by the dozen, or by hal-dozen under the “Golden  Lays Egg” branch.</p>
      </div>
    </VideoProduct>
    <VideoProduct
      id="ricemill"
      partnerId="partnerricemill"
      title="SIDC Ricemill"
      advantagesTitle="Main Points and Advantages of Ricemill"
      advantages={[
        { iconClass:"fa fa-pencil-square-o", name: "Mindoro-milled quality rice"},
        { iconClass:"fa fa-pencil-square-o", name: "State of the art Rice Mill in Nauian, Oriental Mindoro soon"}
      ]}
      videoLinks={[
        { link: "agriculture/video/SIDC-Rice-Milling.m4v", type: 'video/ogg' },
        { link: "agriculture/video/SIDC-Rice-Milling.mp4", type: 'video/mp4' }
      ]}
      offerTitle="Ricemill Products"
      offers={[
        { name: 'Dinorado Quality Rice', iconClass: 'fa fa-check-square-o' },
        { name: 'Sinandomeng Quality Rice', iconClass: 'fa fa-check-square-o' }
      ]}
      photoLinks={[
        'app/dummy-4-250x250.png',
        'agriculture/Ricemill-2.jpg',
        'agriculture/Ricemill-1.jpg',
        'app/dummy-4-250x250.png'
      ]}
      departmentTitle="SIDC Ricemill partnering departments"
      department1={{
        // link: '/',
        src: 'agriculture/SIDC-KooPinoy-Partnering.png'
      }}
      department2={{
        // link: '/',
        src: 'agriculture/SIDC-Paiwi.png'
      }}
      department3={{
        // link: '/',
        src: 'agriculture/SIDC-Sales-Department.png'
      }}
    >
      <h3>SIDC Ricemill at a glance</h3>
      <div className="lead">
        <p>Located at Brgy. Matungao, Socorro, Oriental Mindoro. SIDC rice mill that has capacity of 220 sacks of rice a day.</p>
        <p>The cooperative is also excited to open another state of the art rice mill in Naujan, Oriental Mindoro in addition to this existing ricemill operation.</p>
      </div>
    </VideoProduct>
    <Hero src="agriculture/Rice-Mill-1.jpg" height={500} className="mb-5">
      <Container>
          <Row>
          <Col md="12" className="ml-auto text-right text-white">
              <p className="lead text-white">SIDC’s success in the last 50 years has further convinced us in Department of Agriculture that cooperativism is a most viable way of empowering our farmers and fisherfolk.</p>
              <p className="text-light"><b>MR. EMMANUEL F. PIÑOL</b><br /><small>Secretary, Department of Agriculture</small></p>
          </Col>
          </Row>
      </Container>
    </Hero>
    <VideoProduct
      id="kooplikas"
      partnerId="partnerkooplikas"
      title="Kooplikas Organic Fertilizer"
      advantagesTitle="Main Points and Advantages of Kooplikas"
      advantages={[
        { iconClass:"fa fa-pencil-square-o", name: "OCCP Certified Organic Fertilizer"},
        { iconClass:"fa fa-pencil-square-o", name: "Made from all Natural Ingredients"}
      ]}
      videoLinks={[
        { link: "agriculture/video/SIDC-KoopLikas.m4v", type: 'video/ogg' },
        { link: "agriculture/video/SIDC-KoopLikas.mp4", type: 'video/mp4' }
      ]}
      offerTitle="Kooplikas Products"
      offers={[
        { name: 'Koop Likas Organic Fertilizers 50kg', iconClass: 'fa fa-check-square-o' },
        { name: 'Koop Likas Organic Fertilizer 25kg', iconClass: 'fa fa-check-square-o' },
        { name: 'Koop Likas Organic Fertilizer 5kg', iconClass: 'fa fa-check-square-o' }
      ]}
      photoLinks={[
        'app/dummy-4-250x250.png',
        'app/dummy-4-250x250.png',
        'app/dummy-4-250x250.png',
        'app/dummy-4-250x250.png'
      ]}
      departmentTitle="Kooplikas partnering Departments"
      department1={{
        // link: '/',
        src: 'agriculture/SIDC-KooPinoy-Partnering.png'
      }}
      department2={{
        // link: '/',
        src: 'agriculture/SIDC-Paiwi.png'
      }}
      department3={{
        // link: '/',
        src: 'agriculture/SIDC-Sales-Department.png'
      }}
    >
      <h3>Koop Likas Fertilizers at a glance</h3>
      <div className="lead">
        <p>An OCCP Certified organic fertilizer made from various safe and natural ingredients like chicken dung and hog manure.</p>
        <p>The OCCP Seal guarantees that the production system of Koop Likas is inspected and conforms with the requirements of the Philippine organic agriculture standards.</p>
        <p>This SIDC manufactures this organic fertilizer for rice, corn, ornamentals and vegetable crops.</p>
      </div>
    </VideoProduct>
    <VideoProduct
      id="rightvet"
      partnerId="partnerrightvet"
      title="SIDC Vet Products"
      advantagesTitle="Main Points and Advantages of SIDC Vet Products"
      advantages={[
        { iconClass:"fa fa-pencil-square-o", name: "Quality and Farm Tested Antibiotics and Vitamins"},
        { iconClass:"fa fa-pencil-square-o", name: "Manufactured by an ISO certified facility"},
        { iconClass:"fa fa-pencil-square-o", name: "Effectiveness to improve efficiency gain of livestock"},
        { iconClass:"fa fa-pencil-square-o", name: "Available at almost all SIDC Feeds branches nationwide"}
      ]}
      videoLinks={[
        { link: "agriculture/video/SIDC-Rightvet.m4v", type: 'video/ogg' },
        { link: "agriculture/video/SIDC-Rightvet.mp4", type: 'video/mp4' }
      ]}
      offerTitle="SIDC Vet Products"
      offers={[
        { name: 'RightVet Amoxicillin', iconClass: 'fa fa-check-square-o' },
        { name: 'RightVet Florfenicol', iconClass: 'fa fa-check-square-o' },
        { name: 'RightVet Norfloxxacin', iconClass: 'fa fa-check-square-o' },
        { name: 'CoopVet Chlortetracycline Hydrochloride + Vitamins A, B12 + Biotin', iconClass: 'fa fa-check-square-o' },
        { name: 'PremiereVet Doxycycline + Tiamulin Hydrogen Fumarate + Vitamin A, B12', iconClass: 'fa fa-check-square-o' },
        { name: 'BestVet Chloride +Vit. C + Sodium Bicarbonate + Sodium Chloride + Potassium Chloride', iconClass: 'fa fa-check-square-o' }
      ]}
      photoLinks={[
        'app/dummy-4-250x250.png',
        'app/dummy-4-250x250.png',
        'app/dummy-4-250x250.png',
        'app/dummy-4-250x250.png'
      ]}
      departmentTitle="RightVet partnering Departments"
      department1={{
        // link: '/',
        src: 'agriculture/SIDC-KooPinoy-Partnering.png'
      }}
      department2={{
        // link: '/',
        src: 'agriculture/SIDC-Paiwi.png'
      }}
      department3={{
        // link: '/',
        src: 'agriculture/SIDC-Sales-Department.png'
      }}
    >
      <h3>SIDC Vet Products at a glance</h3>
      <div className="lead">
        <p>Quality and affordable farm-tested Antibiotics and Vitamins for Hogs & Poultry.  A reliable livestock raiser’s partner for a more profitable livestock production business.</p>
        <p>These antibiotics and vitamins have been effective in improving the rate and efficiency of gain in swine, cattle, and poultry.  Being sold in an affordable price, RightVet Water soluble vitamins and anti biotics are available at all Agri-Vet supply section of all Coop Supermarts, Coopmarts and SIDC Feeds outlet</p>
      </div>
    </VideoProduct>
  </Layout>
)

export default IndexPage
